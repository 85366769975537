import React from 'react'
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'

import Homepage from './components/Homepage/Homepage.component'
import InventoryTemplate from './components/InventoryTemplate/InventroryTemplate.component'
import ProductPage from './components/ProductPage/ProductPage.component'
import AboutUs from './components/AboutUs/Aboutus.component'
import Blog from './components/Blog/Blog.component'
import Contact from './components/Contact/Contact.component'
import Sustainability from './components/Sustainability/Sustainability.component'
import TRANSSession from './components/TRANSSesions/Transsesions.component'

import Topbar from './components/Homepage/components/Topbar.component'

import ShoppingCart from './components/ShoppingCard/ShoppingCard.component'

import './styles/style.css'

import { HOMEPAGE, ABOUT_US, BLOG, CONTACT, SUST, TRANSSESSIONS } from './routes/routes'

import { getAllItems } from './requests/inventoryRequest'

interface AppProps {

}

interface AppState {
    inventory: Array<any>
    itemsOnCard: Array<any>
    isShoppingCardOpened: boolean
}

class App extends React.Component<AppProps,AppState>{
    constructor(props: AppProps){
        super(props);
        this.state = {
            inventory: [],
            itemsOnCard: [],
            isShoppingCardOpened: false
        }
    }

    async componentDidMount(): Promise<void>{
        const response = await getAllItems()
        this.setState({
            inventory: response.filter((items: any) => items.image.length > 0 && items.state === 'DISPONIBLE')
        }, () => {
        })
    }

    handleAddItemToCard = (item: any) => {
        const tempItem: any = {...item}
        tempItem["url"] = window.location.href
        this.setState({
            itemsOnCard: [...this.state.itemsOnCard, tempItem]
        })
    }

    handleToogleShoppingCard = () => {
        this.setState({
            isShoppingCardOpened: !this.state.isShoppingCardOpened
        })
    }

    handleDeleteItemFromToCart = (index: number) => {
        const tempArray = this.state.itemsOnCard.filter((_, i) => i !== index);
        console.log(tempArray)
        this.setState({
            itemsOnCard: tempArray
        })
    }

    render(): React.ReactNode {
        return(
            <>
                <Router>
                    <Routes>
                        <Route
                            path={HOMEPAGE}
                            element = {
                                <Homepage inventory={this.state.inventory}/>
                            }
                        />
                        <Route
                            path={ABOUT_US}
                            element = {
                                <AboutUs inventory={this.state.inventory}/>
                            }
                        />
                        <Route
                            path={BLOG}
                            element = {
                                <Blog inventory={this.state.inventory}/>
                            }
                        />
                        <Route
                            path={CONTACT}
                            element = {
                                <Contact inventory={this.state.inventory}/>
                            }
                        />
                        <Route
                            path={SUST}
                            element = {
                                <Sustainability inventory={this.state.inventory}/>
                            }
                        />
                        <Route
                            path={TRANSSESSIONS}
                            element = {
                                <TRANSSession inventory={this.state.inventory}/>
                            }
                        />
                        <Route
                            path='/:category'
                            element = {
                                <InventoryTemplate inventory={this.state.inventory}/>
                            }
                        />
                        <Route
                            path='/product/:id'
                            element = {
                                <ProductPage inventory={this.state.inventory} handleAddItemToCard={this.handleAddItemToCard} handleToogleShoppingCard={this.handleToogleShoppingCard}/>
                            }
                        />
                    </Routes>
                    <Topbar inventory={this.state.inventory} itemsOnCard={this.state.itemsOnCard} handleToogleShoppingCard={this.handleToogleShoppingCard}/>
                    <ShoppingCart itemsOnCard={this.state.itemsOnCard} isShoppingCardOpened={this.state.isShoppingCardOpened} handleToogleShoppingCard={this.handleToogleShoppingCard} handleDeleteItemFromToCart={this.handleDeleteItemFromToCart}/>
                </Router>
            </>
        )
    }
}

export default App