import React from 'react'

import ProductsGrid from './components/ProductsGrid.component'
import WeAre from './components/weAre.component'
import Newsletter from './components/newsletter.component'
import Footer from './components/footer.component'

interface HomepageProps {
    inventory: Array<any>
}

const Homepage = ({inventory}: HomepageProps) => {
    return(
        <div className='homepage-main-container'>
            <div className='video-main-container'>
                <video 
                    src="https://trans-inventory.s3.us-east-2.amazonaws.com/webpage/homepage/videoHomepage.mp4" 
                    autoPlay
                    loop
                    muted
                    playsInline
                />
                <div className='video-front-container'>
                </div>
                <div className='video-front-text'>NUEVA COLLECIÓN</div>
                <div className='moving-transparent-text'>
                    UPCYCLING
                </div>
            </div>
            <ProductsGrid/>
            <WeAre/>
            <Newsletter/>
            <Footer/>
        </div>
    )
}

export default Homepage