import React, {useEffect, useState} from 'react'

import { useParams } from 'react-router-dom'

import Footer from '../InventoryTemplate/FooterGeneral.component'

interface ProductPageProps {
    inventory: Array<any>,
    handleAddItemToCard: (item: any) => void
    handleToogleShoppingCard: () => void
}

const ProductPage = ({inventory, handleAddItemToCard, handleToogleShoppingCard}: ProductPageProps) => {

    const {id}: any = useParams()
    const [product, setProduct]: any = useState(undefined)

    useEffect(() => {
        if(inventory.length > 0){
            const filteredProduct: any = inventory.filter((item) => item._id === id)
            setProduct(filteredProduct[0])
        }   
    }, [inventory, id])

    return(
        <div className='main-product-page'>
            <div className='product-container'>
                <div className='product-image-container'>
                    {
                        product !== undefined ? 
                        product.image.map((item: any, index: number) => 
                            <div className='product-individual-image'>
                                <img src={"https://trans-inventory.s3.us-east-2.amazonaws.com/"+item.key} alt={product.type + product.description}/>
                            </div>
                        ):null
                    }
                </div>
                {product !== undefined ?
                    <div className='product-info-container'>
                        <div className='product-info-title'>{`${product.type} ${product.category.toLowerCase()}`}</div>
                        <div className='prodcut-info-ref'>Referencia: {product.code}</div>
                        <div className='prodcut-info-description-title'>Precio:</div>
                        <div className='prodcut-info-description-text'>
                            {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'COP',
                                currencyDisplay: 'narrowSymbol',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            }).format(product.price)}
                        </div>
                        <div className='prodcut-info-description-title'>Descripción:</div>
                        <div className='prodcut-info-description-text'>{product.description}</div>
                        <div className='prodcut-info-description-title'>Talla:</div>
                        <div className='prodcut-info-description-text'>{product.size}</div>
                        <div className='prodcut-info-description-title'>Categoría:</div>
                        <div className='prodcut-info-description-text'>{product.category}</div>
                        <div className='prodcut-info-description-title'>Disponibilidad:</div>
                        <div className='prodcut-info-description-text'>{product.state}</div>
                        <div className='product-info-add-to-cart-btn' onClick={() => {
                            handleAddItemToCard(product)
                            handleToogleShoppingCard()
                        }}>
                            Añadir al carrito
                        </div>
                    </div>:null
                }
            </div>
            <Footer/>
        </div>
    )
}

export default ProductPage