import React from 'react'

interface AboutUsProps {
    inventory: Array<any>
}

const AboutUs = ({inventory}: AboutUsProps) => {
    return(
        <div className='about-us-main-container'>
        </div>
    )
}

export default AboutUs