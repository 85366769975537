import React from 'react'

interface ContactProps {
    inventory: Array<any>
}

const Contact = ({inventory}: ContactProps) => {
    return(
        <div className='about-us-main-container'>
        </div>
    )
}

export default Contact