import axios from 'axios'

export const getAllItems = async() => {
    try {
        const response = await axios.get('https://trans-db-3c860b6a56d3.herokuapp.com/inventory/');
        return response.data
    }
    catch (error){
        console.log('ERROR GETTING PRODUCTS', error)
    }
}