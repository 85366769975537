export const topbarOptions = [
    {
        'name': 'Ropa',
        'slug': 'ropa',
        'dropdownOptions': [
            {
                'name': 'Upcycling',
                'slug': 'upcycling'
            },
            {
                'name': 'Zero Waste',
                'slug': 'zero-waste'
            },
            {
                'name': 'Second Hand',
                'slug': 'second-hand'
            },
            {
                'name': 'Vintage',
                'slug': 'vintage'
            }
        ]
    },
    {
        'name': 'Accesorios',
        'slug': 'accesorios',
        'dropdownOptions': []
    },
    {
        'name': 'Blog',
        'slug': 'blog',
        'dropdownOptions': []
    },
    {
        'name': 'TRANSessions',
        'slug': 'transessions',
        'dropdownOptions': []
    },
    {
        'name': 'Contacto',
        'slug': 'contacto',
        'dropdownOptions': []
    }
]