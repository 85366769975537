export const productGrids = [
    {
        'name': 'UPCYCLING',
        'slug': 'upcycling',
        'image': 'https://trans-inventory.s3.us-east-2.amazonaws.com/webpage/homepage/upcycling.webp'
    },
    {
        'name': 'ZERO WASTE',
        'slug': 'zero-waste',
        'image': 'https://trans-inventory.s3.us-east-2.amazonaws.com/webpage/homepage/zeroWaste.webp'
    },
    {
        'name': 'SECOND HAND',
        'slug': 'second-hand',
        'image': 'https://trans-inventory.s3.us-east-2.amazonaws.com/webpage/homepage/secondhand.webp'
    },
    {
        'name': 'VINTAGE',
        'slug': 'vintage',
        'image': 'https://trans-inventory.s3.us-east-2.amazonaws.com/webpage/homepage/vintage.webp'
    },
    {
        'name': 'ACCESORIOS',
        'slug': 'accesorios',
        'image': 'https://trans-inventory.s3.us-east-2.amazonaws.com/webpage/homepage/accesorios.webp'
    }
]