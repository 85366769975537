import React, {useState, useEffect} from 'react'

import { useNavigate } from "react-router-dom";

import { topbarOptions } from '../../../data/topbarOptions'

interface TopbarProps {
    inventory: Array<any>
    itemsOnCard: Array<any>
    handleToogleShoppingCard: () => void
}

const Topbar = ({inventory, itemsOnCard, handleToogleShoppingCard}: TopbarProps) => {

    const [openDropdown, setOpenDropddown] = useState(false)
    const [currentIndexSelected, setCurrentIndexSelected] = useState(-1)
    const [cartCount, setCartCount] = useState(0);

    const navigation = useNavigate()

    useEffect(() => {
        setCartCount(itemsOnCard.length)
    }, [itemsOnCard])

    const handleOpenDropdown = (index: number) => {
        setOpenDropddown(!openDropdown)
        setCurrentIndexSelected(index)
    }

    const handleGoToSection = (link: string) => {
        navigation(link)
        setOpenDropddown(false)
        setCurrentIndexSelected(-1)
    }

    return(
        <div className='topbar-main-container'>
            <div className='topbar-main-options'>
                <div className='topbar-categories-container'>
                    {
                        topbarOptions.map((option: any, index: number) => 
                            <>
                                <div className={currentIndexSelected === index ? 'option-container selected-container':'option-container'} onClick={() => {option.dropdownOptions.length > 0 ? handleOpenDropdown(currentIndexSelected === index ? -1:index):handleGoToSection("/"+option.slug)}}>
                                    {option.name}
                                </div>
                                
                            </>
                        )
                    }
                </div>
                {topbarOptions.map((option: any, index: number) => 
                    <>
                    {option.dropdownOptions.length > 0 ?
                        
                        <div className={option.dropdownOptions.length > 0 && openDropdown ? 'topbar-dropdown-menu-main-container':'topbar-dropdown-menu-main-container-closed'}>
                            {
                                option.dropdownOptions.length > 0 && openDropdown ?
                                <div className='topbar-dropdown-menu-container'>
                                <div className='topbar-dropdown-menu-right'>
                                    {
                                        option.dropdownOptions.map((dropItem: any, index: number) => 
                                            <div className='topbar-dropdown-menu-item' onClick={() => {handleGoToSection("/"+dropItem.slug)}}>
                                                {dropItem.name}
                                            </div>    
                                        )
                                    }
                                </div>
                                <div className='top-bar-dropdown-division'/>
                                <div className='topbar-dropdown-menu-left'>
                                    <div className='dp-menu-left-title'>Nuevos productos</div>
                                    <div className='dp-inventory-main-container'>
                                        {
                                            inventory.slice(-5).map((item: any, index: number) => 
                                                <div className='dp-inventory-individual' onClick={() => {handleGoToSection("/product/"+item._id)}}>
                                                    {item.image.length > 0 ?
                                                        <img src={"https://trans-inventory.s3.us-east-2.amazonaws.com/"+item.image[0].key} alt={item.image[0].originalName}/>:null
                                                    }
                                                    <div className='dp-invneotry-name'>{item.type +" "+ item.category.toLowerCase()}</div>
                                                </div>
                                            )
                                        } 
                                    </div>    
                                </div>
                            </div>:null
                            }
                        </div>:null
                    }
                    </>
                )
                }
            </div>
            <div className='logo-container' onClick={() => {navigation('/')}}>
                <img src='/images/logos/logo.svg' alt=''></img>
            </div>

            <div className="shopping-bag-container" onClick={() => {handleToogleShoppingCard()}}>
                <svg
                    className='shopping-bag-icon'
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="black"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M6 6h12l1 14H5z"></path>
                    <path d="M9 6a3 3 0 0 1 6 0"></path>
                </svg>

            {/* Cart Count Circle */}
            {   
                <span>
                    {cartCount}
                </span>
            }
        </div>
        </div>
    )
}

export default Topbar