import React from 'react'
import { MouseParallax} from "react-just-parallax";

const WeAre = () => {

    return(
        <div className='we-are-main-containter'>
            <div className='we-are-main-title'>SOMOS TRANS | SOMOS ÚNICXS</div>
            <div className='we-are-main-text'>#LaModaEsUnActoPolitico</div>
            <div className='we-are-parallax-container'>
                <div className='we-are-parallax-individual-container'>
                    <MouseParallax>
                        <img src={'https://trans-inventory.s3.us-east-2.amazonaws.com/webpage/homepage/parallax/parallax1.jpg'} alt='parallax iamge 1'></img>
                    </MouseParallax>
                </div>
                <div className='we-are-parallax-individual-container'>
                    <MouseParallax>
                        <img src={'https://trans-inventory.s3.us-east-2.amazonaws.com/webpage/homepage/parallax/parallax2.jpeg'} alt='parallax iamge 1'></img>
                    </MouseParallax>
                </div>
                <div className='we-are-parallax-individual-container'>
                    <MouseParallax>
                        <img src={'https://trans-inventory.s3.us-east-2.amazonaws.com/webpage/homepage/parallax/parallax3.jpg'} alt='parallax iamge 1'></img>
                    </MouseParallax>
                </div>
            </div>
        </div>
    )
}

export default WeAre