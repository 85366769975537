import React from 'react'

const Newsletter = () => {
    return(
        <div className='newsletter-main-container'>
            <div className='newsletter-title'>SUSCRÍBETE A NUESTRX NEWSLETTER</div>
            <div className='newsletter-text'>¡Sé el primero en enterarte de las últimas tendencias, ofertas exclusivas y novedades imperdibles!</div>
            <div className='newsletter-bbtn'>SUSCRÍBETE</div>
        </div>
    )
}

export default Newsletter