export const InventoryTemplateData = {
    "upcycling": {
        "copy": "Moda con historia, estilo con conciencia."
    },
    "second-hand": {
        "copy": "Porque lo bueno merece una segunda oportunidad."
    },
    "zero-waste": {
        "copy": "Moda sin residuos, solo estilo."
    },
    "vintage": {
        "copy": "Pasado con estilo, presente con actitud."
    },
    "accesorios": {
        "copy": "Pequeños toques, gran impacto."
    }
}