import React from 'react'

interface FooterProps {}

interface FooterState {}

class Footer extends React.Component<FooterProps,FooterState>{
    constructor(props: FooterProps){
        super(props);
        this.state = {

        }
    }

    render(): React.ReactNode {
        return(
            <div className='main-footer-container'>
                <img className='footer-background' src={"https://trans-inventory.s3.us-east-2.amazonaws.com/webpage/footer/footer.webp"} alt="footer-image"/>
                <div className='footer-info-container'>
                    <div className='footer-logo'>
                        <img src={"/images/logos/logo_white.svg"} alt='trans-logo-footer'/>
                    </div>
                    <div className='footer-info-main-container'>
                        <div className='footer-stores footer-info-container-individual'>
                            <div className='footer-stores-title'>
                                Visitanos
                            </div>
                            <div className='footer-store-container'>
                                <div className='footer-store-title'>Tienda San Felipe</div>
                                <div className='footer-store-text'>Calle 74 # 20C - 19 Local 2</div>
                                <div className='footer-store-schedule'>Horario: Lunes a Sábado de 11am a 7pm</div>
                            </div>
                            <div className='footer-store-container'>
                                <div className='footer-store-title'>Planeta Vintage</div>
                                <div className='footer-store-text'>Carrera 13A #34-57 </div>
                                <div className='footer-store-schedule'>Horario: Martes a Sábado de 11am a 7pm</div>
                            </div>
                        </div>
                        <div className='footer-stores footer-info-container-individual'>
                            <div className='footer-stores-title'>
                                Somxs TRANS
                            </div>
                            <div className='footer-info-text'><a  href='/about-us' target='_self'>Sobre TRANS</a></div>
                            <div className='footer-info-text'><a href='/sustainability' target='_self'>Sostenibilidad</a></div>
                        </div>
                    </div>
                    <div className='footer-division'/>
                    <div className='copyright-social-contiainer'>
                        <div className='copy-container'>
                            © 2025 TRANS. All rights reserved.
                        </div>
                        <div className='footer-social-container'>
                            <div className='footer-social-individual-container'>
                                <img src="/images/icons/instagram.svg" alt='instagram-icon'/>
                                <a href='https://www.instagram.com/_trans_forma/' target='_blank' title='instagram' rel='noreferrer'/>
                            </div>
                            <div className='footer-social-individual-container'>
                            <img src="/images/icons/tiktok.svg" alt='instagram-icon'/>
                                <a href='https://www.tiktok.com/@_trans_forma?_t=ZS-8toD7gDmi8X&_r=1' target='_blank' title='instagram' rel='noreferrer'/>
                            </div>
                            <div className='footer-social-individual-container'>
                            <img src="/images/icons/youtube.svg" alt='instagram-icon'/>
                                <a href='https://youtube.com/@trans6028' target='_blank' title='instagram' rel='noreferrer'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer