import React from 'react'
import { productGrids } from '../../../data/productGrid'
import { useNavigate } from 'react-router-dom'

const ProductsGrid = () => {

    const navigate = useNavigate()

    return(
        <>
            <div className='product-grip-container-main'>
                {
                    productGrids.map((product, index) => 
                        <div className='product-grip-individual-container' key={product.name + index} onClick={() => {navigate(product.slug)}}>
                            <img src={product.image} alt={product.name} className='product-grid-image'/>
                            <div className='product-grid-over-container'>
                                {product.name}
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default ProductsGrid