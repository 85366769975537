import React, {useEffect, useState} from 'react'

interface ShoppingCardProps {
    itemsOnCard: Array<any>
    isShoppingCardOpened: boolean
    handleToogleShoppingCard: () => void
    handleDeleteItemFromToCart: (index: number) => void
}

const ShoppingCart = ({itemsOnCard, isShoppingCardOpened, handleToogleShoppingCard, handleDeleteItemFromToCart}: ShoppingCardProps) => {


    const [openSidebar, setOpenSidebar] = useState(false)
    const [currentPrice, setCurrentPrice] = useState(0)
    const [itemsToShow, setItemsToShow] = useState(itemsOnCard)

    useEffect(() => {
        setTimeout(() => {
            setOpenSidebar(isShoppingCardOpened)
        }, 450)
    }, [isShoppingCardOpened])

    useEffect(() => {
        console.log(itemsOnCard)
        let tempPrice = 0
        itemsOnCard.forEach((item: any) => {
            tempPrice += parseInt(item.price, 10)
        })
        setCurrentPrice(tempPrice)
        setItemsToShow(itemsOnCard)
    }, [itemsOnCard])


    const handleCloseShoppingCart = () => {
        setOpenSidebar(false)
        setTimeout(() => {
            handleToogleShoppingCard()
        }, 450)
    }

    const handleSentMessage = () => {
        let urls:any = []
        itemsOnCard.map((item: any) => {
            urls.push(item.url)
        })
        const phoneNumber = "573193340317"
        let message = `Hola, estoy interesad@ en los siguientes productos:\n\n${urls.join("\n")}`

        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

        // Open WhatsApp with the pre-filled message
        window.open(whatsappUrl, "_blank");
    }

    return(
        <div className={isShoppingCardOpened ? "main-shopping-card-container fade-in":"main-shopping-card-container fade-out"} onClick={() => {handleCloseShoppingCart()}}>
            <div className={openSidebar ? "main-shopping-card-sidebar-opened":"main-shopping-card-sidebar-closed"} onClick={(e) => e.stopPropagation()}>
                <div className='shopping-car-close-btn-container' onClick={() => {handleCloseShoppingCart()}}>
                    <img src={"/images/icons/close.svg"} alt="shopping-cart-close-btn"/>
                </div>
                <div className='shopping-car-title-container'>
                    Carrito de compras ({itemsToShow.length})
                </div>
                <div className='shoppin-cart-items-container'>
                    {
                        itemsToShow.length > 0 ?
                        itemsToShow.map((item: any, index: number) => 
                            <div className='shoppin-cart-item-individual'>
                                <div className='shopping-cart-image-container'>
                                    {item.image && item.image[0] && item.image[0].key ? 
                                        <img src={"https://trans-inventory.s3.us-east-2.amazonaws.com/"+item.image[0].key} alt={""}/>:null
                                    }
                                </div>
                                <div className='shopping-cart-info-container'>
                                    <div className='shopping-cart-delete-item' onClick={() => {handleDeleteItemFromToCart(index)}}>
                                        <img src={"/images/icons/close.svg"} alt="shopping-cart-close-btn"/>
                                    </div>
                                    <div className='shopping-cart-info-price'>
                                        {new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'COP',
                                            currencyDisplay: 'narrowSymbol',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        }).format(item.price)}
                                    </div>
                                    <div className='shoppin-cart-info-description'>{`${item.type} ${item.category.toLowerCase()}`}</div>
                                    <div className='shoppin-cart-info-size'>Talla: {item.size}</div>
                                </div>
                            </div>
                        ):null
                    }
                </div>
                <div className='shopping-cart-hr'/>
                <div className='shopping-cart-subtotal-price-container'>
                    <div className='shopping-cart-price-title'>
                        Subtotal
                    </div>
                    <div className='shopping-cart-price'>
                        {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'COP',
                            currencyDisplay: 'narrowSymbol',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        }).format(currentPrice)}
                    </div>
                </div>
                <div className='shopping-cart-subtotal-price-container'>
                    <div className='shopping-cart-price-title'>
                        Gastos de envío
                    </div>
                    <div className='shopping-cart-price'>
                        TBD
                    </div>
                </div>
                <div className='shopping-cart-subtotal-price-container'>
                    <div className='shopping-cart-price-title'>
                        Total
                    </div>
                    <div className='shopping-cart-price'>
                        {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'COP',
                            currencyDisplay: 'narrowSymbol',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        }).format(currentPrice)}
                    </div>
                </div>
                <div className='shopping-cart-order-btn' onClick={() => {handleSentMessage()}}>
                    TRAMITAR PEDIDO
                </div>
            </div>
        </div>
    )
}

export default ShoppingCart