import React, {useEffect, useState} from 'react'
import { useParams, useNavigate } from "react-router-dom";

import { InventoryTemplateData } from '../../data/inventoryTemplate';

import Footer from './FooterGeneral.component';

interface InventoryTemplateProps {
    inventory: Array<any>
}

const InventoryTemplate = ({inventory}: InventoryTemplateProps) => {

    const { category }: any = useParams()
    const [filteredItems, setFilteredItems] = useState([])

    const navigation = useNavigate()

    const data: any = InventoryTemplateData

    useEffect(() => {
        if(inventory.length > 0){
            const filtered: any = inventory.filter((item) => item.category.toLowerCase().replace(" ","-") === category || (item.category === "UPCYCLED" && category === "upcycling"))
            setFilteredItems(filtered)
        }
    }, [inventory, category])

    const handleSelectProduct = (id: string) => {
        navigation('/product/'+id)
    }


    const handleCapitalizeString = (str: string) => {
        return str.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    }

    return(
        <div className='inventory-template-main-container'>
            <div className='inventory-title'>
                {
                    handleCapitalizeString(category)
                }
            </div>
            <div className='inventory-text'>
                {
                    data[category].copy
                }
            </div>
            <div className='inventory-template-grid'>
                {
                    filteredItems.map((item: any, index: any) => 
                        <div className='inventory-template-individual-item' onClick={() => {handleSelectProduct(item._id)}}>
                            <div className='inventory-template-individual-image'>
                                {item.image.length > 0 ?
                                    <img src={"https://trans-inventory.s3.us-east-2.amazonaws.com/"+item.image[0].key} alt={item.image[0].originalName}/> :null
                                }
                            </div>
                            <div className='inventory-template-individual-info'>
                                <div className='inventory-individual-title'>{item.type}</div>
                                <div className='inventory-individual-text'>Talla: {item.size}</div>
                                <div className='inventory-individual-price'>
                                    {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'COP',
                                    }).format(item.price)}
                                </div>   
                            </div>    
                        </div>
                    )
                }
            </div>
            <Footer/>
        </div>
    )
}

export default InventoryTemplate