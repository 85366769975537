import React from 'react'

interface TRANSSessionProps {
    inventory: Array<any>
}

const TRANSSession = ({inventory}: TRANSSessionProps) => {
    return(
        <div className='about-us-main-container'>
        </div>
    )
}

export default TRANSSession